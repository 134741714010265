import { Controller } from "stimulus"

import "parsleyjs"

export default class extends Controller {

  static targets = ["appearance_Type", "date", "time", "elawid", "roomNumber"]

  connect(){
    $('.filler-fields').hide();
    $('.step_3').hide();
    $('.additional_case_background').hide();

    $('.notifications').hide();
    $('.court_reporter_more').hide();
  }

  court_reporter_toggle(){
    if ($('#request_court_reporter').is(':checked')){
    $('.court_reporter_more').show();
    }else{
      $('.court_reporter_more').hide();
    }

  }


  showAdditionalCaseBackground(){
    $('.additional_case_background').toggle("slow");
  }

  openNoti(){
    $('.notifications').toggle();
  }


  selectedClient(e){
    var client_id = $('#request_client_id').val();

     fetch("/emails/" + client_id)
      .then(response => response.text())
      .then(html => {
        //this.element.innerHTML = html
        var response = JSON.parse(html);

        $('#request_emails_for_report').val(response["report"]);
        $('#request_emails_for_request').val(response["request"]);
      })

  }

  test(r){
    var result = r.charAt(0).toUpperCase() + r.slice(1).toLowerCase();
    console.log(result);
    return result;
  }

  createRequestNoPrefill(e){
    $('.step_2').fadeOut();
    $('.step_3').fadeIn();

    $('.progress-bar').css("width", "75%;");

    $("html, body").animate({ scrollTop: $(document).height() }, 1000);
  }

  displayDateFields(e){
    $('.appearance_date_fields').fadeOut("slow");
    $('.motion_appearance_date_fields').fadeOut("slow");

    $('.another_date_fields').removeClass("hidden");
    $('.another_date_fields').fadeIn("slow");
  }

  createRequest(e){



    window.scrollTo(0,document.body.scrollHeight);

    $(".appearance_date_fields").fadeOut("");
    $(".motion_appearance_date_fields").fadeOut("");

    
    var el_id = e.target.getAttribute('data-elawid');

    $(".elaw-" + el_id).fadeIn();


    $('.select_another_date').html("CONFIRM APPEARANCE INFORMATION");


    $('.create_request').fadeOut();

    $('.another_date_fields').removeClass("hidden");

    this.date = e.target.getAttribute('data-date');
    //console.log(this.date);

    $('.date').val(this.date);

    this.time = e.target.getAttribute('data-time');
    // TODO: D
    // console.log(this.time);
    //
    this.roomNumber = e.target.getAttribute('data-roomNumber');
    $('#request_room_number').val(this.roomNumber);




    if( this.time== null )
    {
        $('.filler-fields').show();
    } else {
        $('.time').val(this.time);
    }

    var r = this.test(e.target.getAttribute('data-appearanceType'));
    this.appearanceType = r;

    var mySelObj= document.getElementById("request_appearance_type_id");

    // Selecting Appearance Type Automatically
    for(var i=0, sL=mySelObj.length; i<sL;i++){
      if(mySelObj.options[i].text == r){
        console.log("trato de select");
        mySelObj.selectedIndex = i;
        break;
      }
    }



    $('.appearanceType').val(this.appearanceType);


    ///document.getElementById("createRequest").submit(); 
    //
  }

}
