// src/controllers/hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["county", "caseNumber"]

  getMetaValue(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`)
      return element.getAttribute("content")
  }

  hideForm(){
      $('.fields_to_hide').hide();
      $(".alert-loading").show();
  }

  showForm(){
    $('.fields_to_hide').show();

    $(".alert-loading").hide();
    $(".alert-success").hide();
  }

  connect() {
    $(".validation-error").hide();
    $(".alert-loading").hide();
    $(".alert-success").hide();

    var input = document.getElementById('null_case_number');

    input.oninvalid = function(event) {
      event.target.setCustomValidity('Please hit back and enter only numerical symbols. If you have any questions, please call 877-737-8539 to speak with one of our team members');
    }

  }

  sendToServer(caseNumber, county){
      fetch("/elaw" , {
          method: "post",
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-CSRF-Token": this.getMetaValue("csrf-token")
          },
        body: JSON.stringify({
          case_number: caseNumber,
          county: county
        })
      })
     .then((response) => response.text())
      .then(html => {
        // {"Result":{},"Message":"Invalid County Name"}
        var result = JSON.parse(html)
        console.log(result["Message"]);
        if (result["Message"] == "Invalid County Name"){

          $('.alert-success').html("Please wait while we redirect you. You can also call us at 877-737-8539 for assistance.");
          $(".progress-bar").removeClass("active");

          $(".alert-success").show();
          $('.alert-success').show();

          $('.create_request').hide();

          //this.showForm();

          window.location.href = result["redirect"];


        }else{
          var id = result["id"];
          //this.element.innerHTML = html
          console.log(id);
          window.location.href = "/elaw/" + id;


        }
      
      });

  }

  displayValidationWarning(){
    $(".validation-error").show();
    $(".progress-bar").removeClass("active");


    $('.create_request').html("NEXT")

    $('.v1_link').show();
    this.showForm();
  }

  checkElaw() {

      $('.create_request').html("LOADING")


      $('.v1_link').hide();

      this.hideForm();


      var county = this.countyTarget.value;
      var caseNumber = this.caseNumberTarget.value;
      console.log(caseNumber);
      console.log(county)

      $(".progress-bar").addClass("progress-bar-animated");
      $(".progress-bar").addClass("active");

    if  (/\//.test(caseNumber) && (county != "" )){
        $(".validation-error").hide();
        this.sendToServer(caseNumber, county);
      }else{
        this.displayValidationWarning();
      }

  }
}
